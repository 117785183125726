<template lang="pug">
  v-container#register.fill-height.justify-center
    //- Terms Dialog
    v-dialog(v-model='termsDialog' width="600" hide-overlay transition="dialog-bottom-transition")
      div(style ='position:relative; height: 100%;')
        terms(v-if="" light style="z-index:999")
    //- content
    v-row(justify="center")
      v-col(cols="12" md="12")
        v-slide-y-transition(appear="")
          v-card.pa-3.pa-md-5.mx-auto(light raised shaped max-width="100%" width="400")
            v-card-title(v-if="userType === 'Buyer'")
              v-spacer
              v-icon(aria-label="Close" @click="$emit('close')")
                | mdi-close
            .text-center
              .my-2
                .text-center.grey--text.body-1.font-weight-light.mb-3
                  | Register with email
                v-snackbar(multiline top color='error' timeout='3500' dense :outlined='!isMobile' v-model="isError")
                  | {{errMsg}}
                v-form(ref="form" v-model="valid")
                  v-text-field.rounded(color="secondary" label="First name" prepend-inner-icon="mdi-face" solo dense filled )
                  v-text-field.rounded(v-model="account.email" color="secondary" label="Email" :rules="rules.emailRules" type="email" prepend-inner-icon="mdi-email" solo dense filled)
                  v-text-field.rounded(solo dense filled v-model="account.password" color="secondary" label="Password" :rules="rules.passwordRules" type="password" prepend-inner-icon="mdi-lock-outline")
                  v-text-field.rounded(solo dense filled v-model="account.repeatPassword" color="secondary" label="Verify your password" :rules="rules.passwordRepeatRules" type="password" prepend-inner-icon="mdi-lock-outline")
                  span.body-1.font-weight-light.pa-1
                    | By checking this box, i agree to the terms and conditions and i'm over 18 years old.
                  v-checkbox(:input-value="false" color="secondary" :rules="rules.termsRules")
                    template(v-slot:label="")
                      span.grey--text I agree to the
                        a.cyan--text.text--darken-3.pl-1(href="#" @click="termsDialog = true" v-text="'terms and conditions, privacy policy, authorization release policy'")
                pages-btn(color="primary" small @click="validate")
                  | GET STARTED
    v-row(style="z-index:999")
      v-col(cols="12").text-center
        b.mr-1(style="color: white") Already have an account ?
        v-btn.m-0( small color='primary' v-if='userType === "contributor"' href="#/pages/login" )
          | Login
        v-btn.m-0(small  color='primary' v-else @click="$emit('switchDialog')" )
          | Login
</template>

<script>
  import terms from '@/views/custom/Terms'

  export default {
    name: 'PagesRegister',
    components: {
      PagesBtn: () => import('./component/Btn'),
      PagesHeading: () => import('./component/Heading'),
      terms: terms,
    },
    props: {
      userType: {
        type: String,
        default: function () {
          return 'contributor'
        },
      },
    },
    data: () => ({
      isError: false,
      errMsg: '',
      valid: true,
      termsDialog: false,
      account: {
        email: '',
        password: '',
        repeatPassword: '',
      },
    }),
    computed: {
      isMobile () {
        return window.innerWidth < 1024
      },
      isLoading () {
        // console.log(this.$store.state.users.isLoading)
        return this.$store.state.users.isLoading
      },
      rules () {
        return {
          emailRules: [
            v => !!v || 'Required',
            v => /.+@.+\..+/.test(v) || 'Email format',
          ],
          passwordRules: [
            v => !!v || 'Required',
            v => (v && v.length >= 8) || 'min length: 8 characters',
          ],
          passwordRepeatRules: [
            v => !!v || 'Required',
            v => (v && v.length >= 8) || 'min length: 8 characters',
            v => (v && v === this.account.password) || 'Passwords not match',
          ],
          termsRules: [
            v => !!v || 'Required',
          ],
        }
      },
      isAuth () {
        return !!this.$store.state.users.user
      },
    },
    methods: {
      validate () {
        if (this.$refs.form.validate()) {
          this.register()
        }
      },
      register () {
        const vm = this
        vm.$store.dispatch('users/register', vm.account)
          .then(function (res) {
            vm.$store.dispatch('users/login', vm.account).then(function (res) {
              vm.$router.replace('/')
              vm.$emit('close')
            })
          }).catch(error => {
            // console.log(error)
            switch (error.code) {
              case ('auth/email-already-in-use'):
                vm.errMsg = 'Email is already been used'
                break
            }
            vm.isError = true
            setTimeout(() => {
              vm.isError = false
            }, 5000)
          })
      },
    },
  }
</script>

<style lang="scss" scoped>
  #register{
    .v-list-item__subtitle{
      -webkic-line-clamp: initial;
      -webkit-box-orient: initial;
    }
  }
</style>
